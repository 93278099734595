<template>
  <div class="home">
    <div
      class="home__hero"
      :class="{ whitelabeled }"
      :style="`--homeHeroOverlay: ${$whitelabel.lightTheme.backgroundPrimary.base}b3;`"
    >
      <div class="home__title">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h1 v-html="homeTitle"></h1>
      </div>
    </div>
    <client-only>
      <div class="home__events">
        <div class="home__search">
          <p>
            {{ $t('home.events.title') }}
          </p>
          <div class="home__search-input">
            <div class="search-input__field">
              <input
                v-model="searchTerm"
                type="search"
                data-cy="searchEvents"
                :placeholder="$t('search.events')"
                autocomplete="off"
                @keyup.esc="searchEvents()"
                @keyup.enter="searchEvents()"
                @keyup="checkSearchEvents()"
              />
              <IconSearch @click.native="searchEvents()" />
            </div>
          </div>
        </div>
        <div class="home__events-wrapper">
          <BEvent
            v-for="event in filteredEvents"
            :key="event.id"
            :event="event"
          />
          <div
            v-if="!filteredEvents.length && eventSearch > 0"
            class="home__no-events home__no-events--search"
          >
            <p>{{ $t('home.noSearchedEvents') }}</p>
          </div>
          <div
            v-if="!filteredEvents.length && eventSearch === 0"
            class="home__no-events"
          >
            <p>{{ $t('home.noEvents') }}</p>
          </div>
        </div>
      </div>
    </client-only>
    <div v-if="$featuredEvents && !getOrganizerId" class="home__events">
      <div class="home__filter">
        <p>
          {{ $t('home.featuredEvents.title') }}
        </p>
        <div>
          <BChip
            :chips="externalFilterOptions"
            @click="filterExternalEvents"
          ></BChip>
        </div>
      </div>
      <div class="home__events-wrapper">
        <BEvent
          v-for="event in externalEvents"
          :key="event.id"
          :event="event"
          :provider="externalEventsProvider"
        />
        <div v-if="!externalEvents.length" class="home__no-events">
          <p>{{ $t('home.noEvents') }}</p>
        </div>
      </div>
      <div class="home__events-actions">
        <div class="home__events-button">
          <BButton
            class="home__events-button--rounded"
            block
            @click.native="loadMoreEvents"
            >{{ $t('home.featuredEvents.loadMore') }}</BButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import IconSearch from '../components/general/icons/IconSearch.vue';
import BEvent from '@/components/general/BEvent';
import BChip from '~/components/general/BChip.vue';
import BButton from '~/components/general/BButton';
import { errorMessage } from '~/utils/utils';
import { toSearchEventData } from '~/utils/facebook';

export default {
  name: 'Home',
  auth: false,
  components: { BEvent, IconSearch, BChip, BButton },
  layout: 'default_v2',
  data() {
    return {
      externalFilterOptions: [
        {
          id: 1,
          label: this.$t('home.featuredEvents.closest'),
          selected: false
        },
        {
          id: 2,
          label: this.$t('home.featuredEvents.popular'),
          selected: true
        }
      ],
      externalEvents: [],
      externalEventsPage: 1,
      externalEventsProvider: null,
      nearMe: false,
      searchTerm: '',
      subcategoryFilter: [],
      dateMenu: false,
      dateFilter: { option: '', dates: [] },
      datePickerDates: [],
      dateFilterOptions: [
        {
          option: this.$t('home.dateFilterOptions.today'),
          dates: [moment(), moment()]
        },
        {
          option: this.$t('home.dateFilterOptions.sevenDays'),
          dates: [moment(), moment().add(7, 'd')]
        },
        {
          option: this.$t('home.dateFilterOptions.month'),
          dates: [moment(), moment().add(1, 'M')]
        }
      ],
      categories: [],
      events: []
    };
  },
  async fetch() {
    // Filtering based on hostname we are serving this page from - localhost defaults to HOST_NAME (since localhost is not supported by backend)
    const hostname = window.location.host.includes('localhost')
      ? process.env.HOST_NAME
      : window.location.host;
    // fetch matching organizer-domains
    const organizerDomains = await this.$bamApi.account.listDomains({
      name: hostname
    });
    // if we found a matching organizer domain, filter for it
    if (organizerDomains.length > 0) {
      this.$store.commit(
        'events/setOrganizerId',
        organizerDomains[0].organizerId
      );
    }
    // ...otherwise fallback to filtering by query-parameter
    else if (this.$route.query.organizer_id) {
      this.$store.commit(
        'events/setOrganizerId',
        this.$route.query.organizer_id
      );
    }
    // ...if nothing matches, reset to no filtering
    else {
      this.$store.commit('events/setOrganizerId', null);
    }

    this.$store.commit('app/setLoading', true);
    // reset search term on page load
    this.$store.commit('events/setSearchTerm', '');
    await this.getCategories();
    await this.fetchEvents();
    if (this.$featuredEvents && !this.getOrganizerId) {
      this.externalEvents = await this.fetchExternalEvents(
        this.externalEventsPage ?? 1,
        this.nearMe
      );
    }
    this.$store.commit('app/setLoading', false);
  },
  fetchOnServer: false,
  computed: {
    ...mapGetters('events', ['getSearchTerm', 'getOrganizerId']),
    ...mapGetters('app', ['getLoading']),
    whitelabeled() {
      return this.$whitelabel.lightTheme.backgroundPrimary.base.length;
    },
    pickerDatesSelected() {
      return (
        this.dateFilter.option ===
          this.$t('home.dateFilterOptions.chooseDates') &&
        this.datePickerDates.length === 2
      );
    },
    eventSearch() {
      return this.isMobileOrTablet
        ? this.getSearchTerm.length
        : this.searchTerm.length;
    },
    isMobileOrTablet() {
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
    },
    dateRangeText() {
      return this.datePickerDates
        .map((date) => this.parseDate(date))
        .join(' - ');
    },
    filteredEvents() {
      const conditions = [];
      if (this.subcategoryFilter.length !== 0) {
        conditions.push(this.filterEventBySubcategory);
      }
      if (this.dateFilter.dates.length !== 0) {
        conditions.push(this.filterEventByDates);
      }
      if (conditions.length > 0) {
        return this.events.filter((event) => {
          return conditions.every((condition) => {
            return condition(event);
          });
        });
      }
      return this.events;
    },
    homeTitle() {
      return this.$t('home.hero.title');
    }
  },
  watch: {
    /**
     * Watch search-term from storage in order to update events if needed
     */
    getSearchTerm: function () {
      this.updateEvents();
    }
  },
  methods: {
    sortEvents() {
      this.events.sort((ev1, ev2) => {
        const a = moment(ev1.startAt);
        const b = moment(ev2.startAt);
        return a.diff(b);
      });
    },
    mapCategories() {
      this.categories
        .reduce(
          (acc, c) => [
            ...acc,
            ...c.subcategory.map((s) => ({
              ...s,
              category: c.id,
              tenantName: c.tenantName
            }))
          ],
          []
        )
        .filter(
          (
            (s) => (o) =>
              ((k) => !s.has(k) && s.add(k))(o.id)
          )(new Set())
        );
    },
    async filterExternalEvents(option) {
      const filterOption = this.externalFilterOptions.find((op) =>
        option.includes(op.id)
      );
      this.nearMe =
        filterOption.label === this.$t('home.featuredEvents.closest') ?? null;
      this.externalEventsPage = 1;
      this.externalEvents = await this.fetchExternalEvents(
        this.externalEventsPage,
        this.nearMe
      );
    },
    async loadMoreEvents() {
      this.externalEventsPage++;
      const events = await this.fetchExternalEvents(
        this.externalEventsPage,
        this.nearMe
      );
      this.externalEvents = [...this.externalEvents, ...events];
    },
    searchEvents() {
      if (this.$enableMetaTracking) {
        if (this.$fb.options.metaPixelId !== this.$metaPixelId) {
          this.$fb.init(this.$metaPixelId);
        }
        this.$fb.enable();
        this.$fb.track('Search', toSearchEventData(this.searchTerm));
        this.$fb.disable();
      }
      this.$store.commit('events/setSearchTerm', this.searchTerm);
    },
    clearArray(array) {
      while (array.length > 0) {
        array.pop();
      }
    },
    parseDate(date) {
      const dateMoment = moment(date);
      return dateMoment.format('D. MMMM YYYY');
    },
    clearDateFilterOption() {
      this.dateFilter = { option: '', dates: [] };
      this.clearArray(this.datePickerDates);
    },
    resetDateFilter() {
      if (!this.dateFilter) {
        this.dateFilter = { option: '', dates: [] };
      }
      if (
        this.dateFilter.option !== this.$t('home.dateFilterOptions.chooseDates')
      ) {
        this.clearArray(this.datePickerDates);
      }
    },
    closePicker() {
      this.dateFilter.dates = this.datePickerDates;
      this.dateMenu = false;
    },
    filterEventByDates(event) {
      return (
        moment(event.startAt).isSameOrAfter(this.dateFilter.dates[0], 'day') &&
        moment(event.startAt).isSameOrBefore(this.dateFilter.dates[1], 'day')
      );
    },
    filterEventBySubcategory(event) {
      return this.subcategoryFilter.includes(event.subcategory.id);
    },
    checkSearchEvents() {
      if (this.searchTerm.length === 0) {
        this.$store.commit('events/setSearchTerm', this.searchTerm);
      }
    },
    // categories aren't used on this page, but this code won't be removed since we may yet re-introduce filter by category
    async getCategories() {
      try {
        this.categories = (
          await this.$bamApi.bamgregator.listCategories({
            with: {
              subcategory: true
            }
          })
        ).data;
        this.mapCategories();
      } catch (e) {
        this.$notifier.showMessage({
          content: this.$t('errors.fetchCategories'),
          type: 'error'
        });
      }
    },
    /**
     * Update own events only
     */
    async updateEvents() {
      this.$store.commit('app/setLoading', true);
      await this.fetchEvents();
      this.$store.commit('app/setLoading', false);
    },
    /**
     * Fetch events from backend, apply filtering if needed
     */
    async fetchEvents() {
      try {
        const searchTerm = this.getSearchTerm || '';
        const organizerId = this.getOrganizerId;
        this.events = (
          await this.$bamApi.bamgregator.listEvents({
            with: {
              ticket_config: true,
              subcategory: true
            },
            organizer_id: organizerId,
            sort_by: 'created_at',
            direction: 'desc',
            future_events: true,
            name: searchTerm
          })
        ).data;
        this.sortEvents();
      } catch (e) {
        this.$notifier.showMessage({
          content: this.$t('errors.fetchEvent'),
          type: 'error'
        });
      }
    },
    async fetchExternalEvents(pageNumber, nearMe = false) {
      try {
        const externalEventsData =
          await this.$bamApi.bamgregator.listExternalEvents({
            page: pageNumber,
            near_me: nearMe,
            sort: 'popularity',
            page_size: 12
          });
        // TODO: change this code when/if we get more providers
        this.externalEventsProvider = externalEventsData[0].name;
        return externalEventsData[0].events;
      } catch (e) {
        this.$notifier.showMessage({
          content: errorMessage(e, this.$t('errors.fetchEvent')),
          type: 'error'
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.whitelabeled {
  background: url(assets/img/event.webp) center center no-repeat;
  background-size: cover;
  &::after {
    background: linear-gradient(
      var(--homeHeroOverlay, #dddddd) 8%,
      var(--v-backgroundGray-lighten2) 100%
    );
  }
}
.home__events-button--rounded {
  border-radius: 4px;
}
</style>
